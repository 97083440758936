import FlatList from "./FlatList"
import Banner from "./Banner"
import React from "react"
// import TeamList from "./TeamList"
import Title from "./Title"
import References from "./References"
import Subscribe from "./Form/Subscribe"
// import BestFlatList from "./BestFlatList"
import { useState, useEffect } from "react";
import * as contentful from "contentful"
import Meta from "./Meta/Meta"


const Marbella=({version})=>{
    let title = "Marbella"
    let description = "Exklusivt, glamour och lyx kan du hitta i paradiset Marbella"
    let canonicalLink = 'https://platsisolen.se/marbella'
    if (version === "hus") {
        title = "Köpa hus i Marbella"
        description = "Vi hjälper dig med dit husköp i Marbella"
    }
    else if (version === "lagenhet") {
        title ="Platsisolen.se - lägenhet i Marbella"
        description = "Välkommna till Marbella! Här kan du hitta underbara lägenheter och hus."
        canonicalLink = 'https://platsisolen.se/lagenhet-marbella'
    }
    else {
        
    }
    // State to store the fetched data
    const [data, setData] = useState(null);

  // State to track if the data is still loading
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Fetch data from API on component mount
        const fetchData = async () => {
          try {
            const client = contentful.createClient({
                space: 'cx70k9zsq5af',
                environment: 'master', // defaults to 'master' if not set
                accessToken: 'F2kMyptLDLJVSZnnmNilbGhjaos8o6eY12pIHBiXlzc'
              })
              
            //   client.getEntry('1D9Vo6dCTArZxuX0e6GVlq')
            //     .then((entry) => console.log(entry))
            //     .catch(console.error)
            
           await client.withoutUnresolvableLinks.getEntries()
                .then(
                    (entries) => {
                        console.log(entries)
                        // Store the data in state
                        setData(entries.items)
                    }
                )
                .catch(console.error)
            setLoading(false); // Set loading to false once data is fetched
          } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
          }
        };
    
        fetchData();
      }, []); // Empty dependency array ensures the effect runs only once

    return (
        <React.Fragment>
            <Meta title={title} description={description} absoluteImageUrl = {'https://platsisolen.se/img/solnedgang.webp'} canonicalLink = {canonicalLink} keywords={'lägenhet Marbella, lägenhet i Marbella, hus Marbella, hus i Marbella'}/>
            <Banner page={"marbella"}/>
            <section className="section-best-estate"> 
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <Title title={"Marbella"} description={"Lyx, glamour, natur och familjeliv i Marbella"} size= {2}/>
                            <p>Marbella ligger precis vid Medelhavet och erbjuder ett härligt microklimat året runt. Här kan du njuta av sol och värme och allt som Marbella har att erbjuda. I Marbella stad ligger gamla stan eller Casco antiguo som är dess namn på spanska. 
                               Här kan du strosa runt på de små gatorna som kantas av de vita husen, handla i de många trevliga butikerna, äta på någon utav restaurangerna på eller slå dig ner en stund på Plaza de los Naranjos för att titta på folk och 
                               alla de vackra apelsinträden. Marbella har allt från vackra stränder, lyxiga beachclubs, mängder av restauranger och shopping i alla prisklasser, vandringsleder med underbar utsikt, golfbanor i världsklass och padel för både 
                               nybörjare och proffs. Med buss eller bil kan man enkelt ta sig till La Cañada shopping center där det finns massor av affärer, restauranger, bio och bowling. </p>
                            <p>I Puerto Banus som ligger precis vid havet kan du sitta på någon utav serveringarna och dricka ditt morgonkaffe samtidigt som du ser ut över hamnen där yatcherna ligger på rad. Här finns ett stort utbud utav restauranger, barer och nattklubbar för den partysugna. 
                               På dagtid kan du shoppa i någon utav alla märkesbutiker som Gucci, Dolce & Gabbana, Michael Kors, Luis Vuitton eller det stora varuhuset El Corte Inglés.
                            </p>
                            <p>Cirka 2 mil ifrån Marbella ligger den mysiga, lugna bergsbyn Benahavis mellan bergen och grönskan i den så kallade gyllene triangeln som består av Marbella, Estepona och Benahavis. Benahavis har flera förstklassiga restauranger och här kan du äta mat av bästa kvalité 
                                och ofta gjord på lokalt odlade råvaror. Här kan golftentusiasten hitta välkända golfbanor som La Quinta och Los Arqueros. I Benahavis som omringas av berg finns många natursköna vandringsleder och är perfekt om man tycker om att vara i naturen. 
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            {version === "lagenhet" || version === "hus" && 
            <section className="section-th"> 
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <img src="/img/apelsintrad.webp" fetchpriority="low" alt="apelsintrad" className="w-75 rounded mx-auto mt-2rounded mx-auto mt-4" />
                        </div>
                        <div className="col-lg-6">
                            <img src="/img/palmer och hus.webp" fetchpriority="low" alt="palmer och hus" className="w-75 rounded mx-auto mt-4" />
                         </div>
                    </div>
                </div>
            </section>
            }
            {version === "lagenhet" && 
            <section className="section-best-estate"> 
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                             <Title title={"Lägenhet i Marbella"} size= {3}/>
                            <p>Att köpa en lägenhet i Marbella är en bra investering. Om man vill bo så att man har gångavstånd och ett stort utbud av affärer och restauranger är det en lägenhet i stadskärnan perfekt. Har du tillgång till bil blir utbudet väldigt stort, Marbella är en stor kommun med 
                                många attraktiva områden att bo i som t ex Puerto Banus, Nueva Andalucia, Elviria, Monte Paraiso eller San Pedro. Det finns mycket att välja på.</p>
                           
                           
                        </div>
                    </div>
                </div>    
            </section>
            }
              {version === "hus" && 
            <section className="section-best-estate"> 
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                             <Title title={"Hus i Marbella"} size= {3}/>
                            <p>I Marbella området är utbudet av hus stort beroende på vad du är intresserad av. Här kan du hitta allt ifrån ett mindre townhouse till en lyxig stor villa eller ett palats. När man köper ett hus i Marbella bör man fundera över vad man vill ha nära till. Är man t ex en 
                                familj kanske det är viktigt att bo nära barnens skola. Det finns många bra spanska och internationella skolor i Marbella och även en svensk skolan och flertalet svenska butiker. Området Nueva Andalucia har länge varit ett mycket omtyckt område att köpa bostad i för svenskar. 
                                Andra områden är t ex Golden Mile, Sierra Blanca, östra Marbella där t ex Elviria och La Mairena ligger, västerut finns San Pedro de Alcantara och Guadalmina, La Zagaleta. Det finns mycket att välja på i Marbella. 
                            </p>    
                        </div>
                    </div>
                </div>    
            </section>
            }
            {loading ? (
                <p>Loading data...</p>
            ) : (
                <FlatList properties={data} />
            )}
            {/* <BestFlatList/> */}
            <Subscribe/>
            {/* <TeamList/> */}
            <References/>
        </React.Fragment>
    )
}

export default Marbella;